@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Courier+Prime:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,h2,h3,h4,h5,h6,p {
  margin: 0;
  padding: 0;
}



.logo_container {
  max-width: 170px;
  border-radius: 14px;
  max-height: 170px;
  overflow: hidden;
  box-shadow: 0px 8px 16px 0px rgba(154,173,194,0.26);
}

.logo_container #upload-photo {
  opacity: 0;
  z-index: -1;
  display: inherit;
  height:0;
}

.logo_container     label {
  max-width: 100%;
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  border-radius: 14px;
}

.logo_container   .add_photo {
  width: 150px;
  height: 80px;
}

.logo_container img {
  max-width: 100%;
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}

.table_container {
  max-width: 100vw;
  padding: 40px;
  overflow: hidden;
}

@media screen and (max-width: 650px){
  .active_lnav_background {
    position: fixed;
    top: 90px;
    left: 0;
    min-width: 100vw;
    min-height: 100vh;
    z-index: 2000;
    background: rgba(0,0,0, 0.16);
  }
}

@font-face {
  font-family: 'Gotham';
  src: url(/static/media/Gotham-Bold.f9a5c924.otf) format('otf'),
     url(/static/media/GothamBold.108854bb.ttf) format('truetype');
   font-weight: 600;
   font-style: normal;
 }

 @font-face {
  font-family: 'ItauDisplay';
  src: url(/static/media/ItauDisplay_Bd.445df2b4.ttf) format('truetype');
   font-weight: bold;
   font-style: normal;
 }


 @font-face {
  font-family: 'ItauDisplay';
  src: url(/static/media/ItauDisplay_Rg.2f4e57da.ttf) format('truetype');
   font-weight: 400;
   font-style: normal;
 }



 table {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

td {
  line-break: normal;
}

.main_loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid gray; /* Blue */
  border-radius: 50%;
  z-index: 400;
  width: 22px;
  position: fixed;
  top: 50vh;
  left: 50vw;
  -webkit-transform: translate(-50vw, -50vh);
          transform: translate(-50vw, -50vh);
  height: 22px;
  margin: 15px 0;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}


.select-filter {
  margin-top: 10px;
}

::-webkit-scrollbar {
  width: 10px;
  background: #EDEDED;
  border: none;
  cursor: pointer;
}

::-webkit-scrollbar-track-piece {
  background-color: #F5F8F9;
  
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgray;
  border: 3px solid white;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-primary);
}

th {
  outline: none;
  overflow: hidden;
}

input[type="number"]::-webkit-inner-spin-button, 
input[type="number"]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
}
input[type="number"] { 
    -moz-appearance: test-field; 
}

title {
  text-transform: capitalize;
}
